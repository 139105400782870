@import '../../../styleVariables/styleVariables.scss';

.main_admin_panel_content{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    gap: 20px;
    padding: 0 20px 0 70px;

    svg {
        font-size: 50px;
        color: $dark-green;
    }
}

.main_admin_panel_txt{
    font: 700 35px $basic-font;
    color: $dark-green;
    text-align: center;
}

// Styles for all admin pages

.admin_page_content{
    display: flex;
    text-align: center;
    flex-flow: column;
    min-height: 100vh;
    gap: 20px;
    padding: 130px 20px 70px 90px;
}

.admin_page_title{
    font: 700 30px $basic-font;
    color: $dark-green;
}

.admin_filters_block{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.admin_search_input {
    width: 100%;
    max-width: 50%;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background: $medium-light-green;
    transition: .5s all ease;
    outline: none;

    &::placeholder{
        color: $white;
        font: 400 15px $basic-font;
        opacity: .7;
    }

    &:focus {
        background: $light-green;

        &::placeholder{
            color: $dark-green;
        }
    }
}

.admin_create_element{
    padding: 8px 15px;
    background: $medium-light-green;
    color: $white;
    font: 700 15px $basic-font;
    transition: .5s all ease;
    border-radius: 5px;
    border: none;
    outline: none;

    &:hover {
        color: $white;
        background: $dark-green;
    }
}

.admin_empty_table{
    text-align: center;
    .admin_create_element{
        margin-top: 8px;
    }
}

.admin_controll_btn{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.admin_delete_btn{
    color: rgba(255, 0, 0, 0.702);
    font-size: 25px;
    padding: 2px;
    border-radius: 5px;
    background: $light-green;
    opacity: .7;
    transition: .5s all ease;
    border: none;
    outline: none;

    &:hover {
        opacity: 1;
    }
}

.admin_rewrite_btn{
    color: $dark-green;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
    background: $light-green;
    opacity: .7;
    transition: .5s all ease;
    border: none;
    outline: none;

    &:hover {
        opacity: 1;
    }
}

.admin_table_head{
    background: $medium-light-green;

    th {
        color: $light-green;
        font: 700 13px $basic-font
    }
}

.admin_table_row{
    td {
        padding: 7px;
        color: $dark-green;
        font: 700 14px $basic-font
    }
}


// Modal styles

.admin_administration_modal{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.admin_modal_tit{
    font: 700 17px $basic-font;
    color: $dark-green;
}

// Input styles 

.admin_modal_label {
    width: 100%;
    font: 400 17px $basic-font;
    padding: 5px;
    border-radius: 3px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    outline: none !important;
    transition: .4s all ease;
    box-shadow: 0px 0px 20px -18px;
    background: $light-green;
}

.admin_modal_label:focus {
    border-bottom: 2px solid $dark-green;
    border-left: 2px solid $dark-green;
}

.admin_modal_btns{
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 10px auto;
}

.admin_modal_save_btn,
.admin_modal_cancel_btn{
    padding: 7px 10px;
    font: 700 15px $basic-font;
    border-radius: 5px;
    transition: .5s all ease;
}
.admin_modal_save_btn{
    background: $medium-green;
    color: $white;

    &:hover{
        background: $dark-green;
    }
}
.admin_modal_cancel_btn{
    background: rgba(146, 2, 2, 0.727);
    opacity: .7;
    color: $white;
    cursor: pointer;

    &:hover{
        opacity: 1;
    }
}

.form_error_txt{
    font: 700 14px $basic-font;
    color: rgb(107, 5, 5);
    margin-top: -15px;
}

.admin_table_block{
    position: relative;
    min-height: 100px;
    .spinner{
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}