@import '../../styleVariables/styleVariables.scss';

.information_accord_btn{
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    font: 700 17px $basic-font;
    color: $white;
    background: $dark-green-opacity;
    transition: 0.5s all ease;
    border: none;

    &:hover {
        background: $dark-green !important;
    }
}

.information_questions_accord_box,
.information_analyses_accord_box{
    display: flex;
    flex-flow: column;
    gap: 5px;
}

.information_question_btn,
.information_analyses_btn{
    background: $medium-light-green;
    font: 400 14px $basic-font;
    color: $white;
    border-radius: 0 10px 0 0;

    &:hover {
        background: $medium-green !important;
        color: $white !important;
    }
}

.information_question_answer,
.information_analyses_descr{
    font: italic 400 15px $basic-font;
    color: $dark-green;
}

.information_registery_box{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-around;
    gap: 20px;
    box-shadow: 0px -1px 10px 2px rgba(7, 87, 91, 0.2);
    border-radius: 5px;
    margin: 20px auto;
}

.information_registery_contacts,
.information_registery_info{
    display: flex;
    justify-content: space-around;
    flex-flow: column;
    gap: 5px;
    text-align: center;
}

.information_registery_name{
    font: 700 18px $basic-font;
    color: $dark-green;
    border-bottom: 2px solid $medium-green;
}

.information_registery_contacts_text,
.information_registery_weekdays_text{
    font: 700 17px $basic-font;
    color: $dark-green;
}

.information_registery_contacts_box{
    display: flex;
    flex-flow: column;
    gap: 5px;
}

.information_registery_contacts_phone,
.information_registery_contacts_days{
    font: 400 15px $basic-font;
    color: $dark-green;
}

.information_registery_contacts_days{
    font-weight: 700;

    span {
        font-weight: 400;
        margin-left: 10px;
    }
}