@import "../../styleVariables/styleVariables.scss";

.about_clinic_txt_wrapper,
.about_clinic_structure_wrapper,
.about_clinic_specialties_wrapper,
.about_clinic_services_wrapper,
.about_clinic_administration_wrapper {
    padding: 20px 20px 40px;

    &:nth-child(odd) {
        background: $light-green;
    }
}

.about_clinic_tit_block {
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.about_clinic_tit_txt,
.about_clinic_services_text {
    font: 400 17px $basic-font;
    color: $dark-green;
    margin: 5px auto;
}

.about_clinic_structure_list {
    text-align: center;
    list-style: none;
    display: flex;
    gap: 10px;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    & > a {
        max-width: 400px;
        width: 100%;
        padding: 10px;
        font: 700 17px $basic-font;
        columns: $dark-green;
        background: $white;
        border-radius: 5px;
        color: $dark-green;
        transition: 0.5s all ease;

        &:hover {
            color: $white;
            background: $medium-green;
        }
    }
}

.about_clinic_specialties_list {
    text-align: center;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-around;

    li {
        padding: 10px;
        font: 700 17px $basic-font;
        columns: $dark-green;
        background: $light-green;
        border-radius: 5px;
        transition: 0.5s all ease;

        &:hover {
            background: $medium-green;
            color: $white;
        }
    }
}

.about_clinic_administration_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.administration_box {
    width: 100%;
    max-width: 350px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    gap: 5px;
    box-shadow: 0px -1px 10px 2px rgba(7, 87, 91, 0.2);
    transition: 0.5s all ease;

    &:hover {
        background: $light-green;
    }

    hr {
        border: none;
        height: 2px;
        width: 100%;
        background: $dark-gradient;
    }
}

.admin_person_position {
    font: italic 400 15px $basic-font;
    color: $dark-green;
}

.admin_person_name {
    font: 700 17px $basic-font;
    color: $dark-green;
}

.admin_person_phone {
    font: 700 15px $basic-font;
    color: $dark-green;
    text-decoration: none;
}

.about_clinic_documents_content {
    padding: 0px 30px 60px;
}

.documents_list {
    list-style: disc;
    color: $medium-green;
}

.document_box {
    font: 500 19px $basic-font;

    a {
        text-decoration: none;
        color: $medium-green;

        &:hover {
            text-decoration: underline;
        }
    }
}
