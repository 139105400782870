@import '../../../styleVariables/styleVariables.scss';

.admin_header_wrapper{
    background: $dark-green;
    position: fixed;
    z-index: 2;
    width: 100%;
}

.admin_header_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.admin_header_link{
    outline: none;
}

.admin_header_logo_img{
    width: 100%;
    max-width: 140px;
}

.sign_out,
.main_admin_link {
    font: 700 15px $basic-font;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: .5s all ease;
    text-decoration: none;
    background: $light-green;
    margin: 0px 5px;
    color: $dark-green;

    &:hover{
        background: $medium-light-green;
        color: $light-green;
    }
}