@import '../../styleVariables/styleVariables.scss';

.partners_content{
    display: flex;
    justify-content: center;
    gap: 30px;
}

.parner_link{
    display: block;
    transition: .5s all ease;
    filter: grayscale(70);

    &:hover {
        filter: grayscale(0);
    }
}

.partner_img{
    width: 100%;
    max-width: 100px;
    aspect-ratio: 3/2;
    object-fit: contain;
    transition: .5s all ease;
    mix-blend-mode: color-burn;
}