@import '../../styleVariables/styleVariables.scss';

.breadcrumbs{
    position: absolute;
    bottom: -15px;
    left: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    background: $dark-green;
    padding: 5px;
    z-index: 1;
    border-radius: 0 5px 5px 0;
}
.crumb {
    position: relative;
    transition: .5s all ease;

    &:hover{
        color:$medium-light-green;
    }

    &::after{
        content: '/';
        margin-left: 10px;
        color: $white;
    }

    &:last-child::after{
        display: none
    }

    a {
        text-decoration: none;
        font: 400 14px $basic-font;
        color: $white;
    }
}