@import '../../styleVariables/styleVariables.scss';

.want_to_find_services_block{
    padding: 20px;
    text-align: center;
}

.want_to_find_block_tit{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.want_to_find_services_block_sec_txt{
    font: 400 17px $basic-font;
    color: $dark-green;

    span {
        font-weight: 700;
    }
}

.want_to_find_accord_btn{
    padding:10px;
    border-radius: 5px;
    width: 100%;
    max-width: 270px;
    justify-content: center;
    gap: 10px;
    margin: 30px auto;
    cursor: pointer;
    font: 700 15px $basic-font;
    color: $white;
    background: $dark-green-opacity;
    transition: .5s all ease;
    border: none;

    &:hover {
        background: $dark-green
    }
}

// VPO help

.want_to_find_vpo_help_block{
    background: $light-green;
    padding: 20px;
    text-align: center;
}

.want_to_find_vpo_help_text{
    font: 400 17px $basic-font;
    color: $dark-green;
}

.want_to_find_vpo_help_btn{
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background: $dark-green-opacity;
    font: 700 17px $basic-font;
    color: $light-green;
    text-decoration: none;
    margin: 20px auto;  
    text-align: center;
    transition: .5s all ease;
    display: table;

    &:hover {
        background: $dark-green;
        transform: scale(1.05);
    }
}

// End VPO help

// Vacancies

.empty_vacancies{
    text-align: center;
    font: 700 20px $basic-font;
    color: $medium-green;
    margin-bottom: 20px;
}

.want_to_find_vacancies_content{
    padding: 20px;
}

.want_to_find_vacancies_contacts{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
}

.want_to_find_vacancies_contacts_txt,
.want_to_find_contact_txt{
    font: 700 15px $basic-font;
    color: $dark-green;
    text-align: center;
}

.want_to_find_vacancies_contacts_box{
    display: flex;
    flex-flow: column;
}

.want_to_find_vacancies{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 20px auto;

    div {
        width: 100%;
        max-width: 300px;
        text-align: center;
        display: flex;
        gap: 10px;
        flex-flow: column;
        box-shadow: 0px -1px 10px 2px rgba(7, 87, 91, 0.2);
        padding: 10px;
        border-radius: 5px;
        transition: .5s all ease;

        &:hover {
            background: $light-green;
        }

        hr {
            height: 3px;
            background: $dark-gradient;
        }
    }
}

.want_to_find_vacancies_box_tit{
    font: 700 17px $basic-font;
    color: $medium-green;
}

.want_to_find_vacancies_box_sec_txt{
    font: italic 400 15px $basic-font;
    color: $dark-green;
}

// End vacancies

// Internship

.want_to_find_internship_block{
    padding: 20px;
    background: $light-green;
}

.want_to_find_block_sec_txt{
    font: 400 17px $basic-font;
    color: $dark-green;
    text-align: center;
}
// End Internship