@import '../../styleVariables/styleVariables.scss';

.schedole_content{
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
}

.schedole_box{
    width: 100%;
    max-width: 350px;
    min-height: 250px;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px -1px 10px 2px rgba(7, 87, 91, 0.2);
    background: $white;
    transition: .5s all ease;

    &:hover {
        transform: scale(1.05);

        .animation_block{
            right: -100px;
            bottom: -52px;
        }
    }
}

.schedule_link{
    text-decoration: none;
    display: flex;
    flex-flow: column;
    gap: 20px;
    justify-content: space-evenly;
    min-height: 200px;
}

.devision_info{
    text-align: center;
}

.devision_title{
    font: 700 20px $basic-font;
    color: $dark-green;
}

.devision_adress{
    font:italic 400 17px $basic-font;
    color: $medium-green;
}

.devision_schedune{
    p{
        font: 700 17px $sans-font;
        color: $dark-green;

        span {
            font: 400 17px $basic-font
        }
    }
}

.schedule_specialties_btn{
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
    gap: 10px;
    margin: 0px auto;
    cursor: pointer;
    font: 700 15px $basic-font;
    color: $white;
    background: $dark-green-opacity;
    transition: 0.5s all ease;
    border: none;

    &:hover {
        background: $dark-green;
    }
}

.schedule_specialties_accord_body{
    background: $light-green;
    padding: 5px 20px;
    border-radius: 0 0 5px 5px;
}

.schedule_specialties_list{
    font: 400 15px $basic-font;
    color: $dark-green;
}