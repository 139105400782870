@import '../../../styleVariables/styleVariables.scss';

.admin_aside_wrapper{
    border-right: 3px solid $light-green;
    width: 100%;
    position: fixed;
    top: 0;
    left: -230px;
    z-index: 1;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 100vh;
    background: $dark-green;
    transition: .5s all ease;
    padding:70px 20px 0px;
    gap: 10px;

    &:hover{
        left: 0px;
    }
}

.admin_aside_link_box{
    display: flex;
    gap: 5px;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    position: relative;
}

.admin_aside_link{
    font: 900 15px $basic-font;
    text-decoration: none;
    color: $dark-green;
    padding: 5px;
    border-radius: 5px;
    transform: rotate(0deg);
    background: $light-green;
    width: 100%;
    max-width: 170px;
    text-align: center;
    transition: .5s all ease;
    
    &:hover{
        color: $light-green;
        background: $medium-light-green;
    }
}

.admin_aside_link_box svg{
    font-size: 25px;
    position: absolute;
    color: $light-green;
    right: -5px;
}