@import '../../styleVariables/styleVariables.scss';


.news_page_slider{
    padding: 20px;
    background: $light-green;
}

.news_list_box{
    padding: 20px;
    display: flex;
    flex-flow: column;
    gap: 20px;
}

.news_page_block_tit{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.news_block_box{
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: 0px -1px 10px 1px rgba(7, 87, 91, 0.2);
    border-radius: 5px;
    transition: .5s all ease;
    text-decoration: none;

    &:hover {
        background: $light-green;
    }
}

.news_search_input{
    margin: 20px auto 0px;
    display: block;
    width: 100%;
    max-width: 50%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: $medium-light-green;
    transition: .5s all ease;
    outline: none;

    &::placeholder{
        color: $white;
        font: 400 15px $basic-font;
        opacity: .7;
    }

    &:focus {
        background: $light-green;

        &::placeholder{
            color: $dark-green;
        }
    }
}

.news_block_img_wrapper{
    display: flex;
}

.news_block_img{
    width: 100%;
    object-fit: cover;
    aspect-ratio: 3/2;
    height: 100%;
}

.news_block_title{
    font: 700 20px $basic-font;
    color: $dark-green;
}

.news_block_short_descr{
    font: 400 15px $basic-font;
    color: $dark-green;
    margin: 10px;
}

.news_block_date{
    font: italic 400 15px $basic-font;
    color: $medium-green;
    margin-top: 10px;
}

.news_pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 20px auto;

    p{
        font: 700 17px $basic-font;
        color: $dark-green;
        cursor: pointer;
        transition: .5s all ease;
        padding: 5px 10px;

        &:hover { 
            background: $dark-green;
            color: $white;
            border-radius: 5px;
        }
    }
}

.actived_pagination{
    background: $dark-green;
    color: $white !important; 
    border-radius: 5px;
}