@import '../../styleVariables/styleVariables.scss';

.main_wrapper{
    background: linear-gradient(to top, rgba(0, 59, 70,.75), rgba(102, 165, 173,.66)),url('../../img/main-head-bg.jpeg') no-repeat center;
    background-attachment: fixed;
    background-size: cover;
}
// Section padding
.section_block{
    padding: 20px;
}

// Title of block
.main_block_tit{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

// Head block

.main_head_block{
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center
}

.main_head_content{
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
}


// Head title text

.main_head_title{
    width: 60%;
}

.main_head_title_txt{
    font: 700 45px $basic-font;
    color: $white;
    line-height: 40px;
    margin: 10px auto 15px;
}
.main_head_title_sec{
    font: 400 17px $basic-font;
    text-transform: uppercase;
    color: $white;
}

// Head links

.main_head_links{
    display: flex;
    flex-flow: column;
    width: 40%;
    gap: 5px;
}

.main_head_link{
    position: relative;
    padding: 25px 10px;
    background: $dark-green-opacity;
    color: $light-green;
    font: 700 20px $basic-font;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    transition: .5s all ease;
    border-radius: 5px;

    &::before{
        content: '';
        background: url(../../img/arrow.svg) no-repeat center;
        background-size: cover;
        width: 17px;
        top: 40%;
        left: 20px;
        height: 17px;
        position: absolute;
        transform: rotate(-90deg);
        transition: .5s all ease;
    }

    &:hover {
        background: $dark-green;
        transform: scale(1.05);

        &::before{
            left: 35px;
        }
    }
}
// End head block

// News slider block

.main_news_block{
    background: $white;
}

.main_news_block_content{
    padding-bottom: 30px;

    & > a {
        margin:  10px auto;
    }
}

// End news slider block

// Schedule block

.main_schedole_block{
    background: $light-green;
}

.main_schedole_content{
    padding-bottom: 30px;
}

// End schedule block

// Doctors block

.main_doctors_block{
    background: $white;
}

.main_doctors_content{
    padding:40px 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}

.main_doctors_block_tit{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    margin: 10px 0 10px;
    text-transform: uppercase;
}

.main_doctors_block_sec{
    font: italic 400 20px $basic-font;
    margin: 10px auto 20px;
    color: $dark-green;
}

.main_doctors_img{
    width: 100%;
    max-width: 600px;
    box-shadow: 0px -1px 10px 2px rgba(7, 87, 91, 0.2);
    border-radius: 5px;
}

// End doctors block

// Partners block

.main_partners_block{
    background: $light-green;
}

.main_partners_content{
    padding-bottom: 30px;
}

// End partners block

@media screen and (max-device-width: 768px) {
    .main_head_block{
        padding: 120px 20px 70px;
    }
    .main_head_content{
        flex-flow: column;
        justify-content: center;

        div {
            width: 100%;
        }
    }
    .main_head_title{
        text-align: center;
    }
    .main_head_title_txt{
        font-size: 30px;
    }
    .main_head_link{
        &:hover { 
            transform: none;
        }
    }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1000px) {
    .main_head_block{
        padding: 180px 20px 100px;
    }
}