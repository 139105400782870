@import '../../styleVariables/styleVariables.scss';

.head_block_wrapper{
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    background: linear-gradient(to top, rgba(0, 59, 70,.75), rgba(102, 165, 173,.66)),url('../../img/franko_st.jpeg') no-repeat center;
    background-attachment: fixed;
    background-size: cover;
}

.head_block_content{
    width: 100%;
    max-width: 1200px;
    text-align: center;
}

.head_block_txt{
    font: 700 35px $basic-font;
    color: $white;
    line-height: 40px;
    margin: 10px auto 15px;
}