@import '../../styleVariables/styleVariables.scss';


.error_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 100%;
    min-height: 100vh;

    h2{
        font: 700 12rem $basic-font;
        color: $medium-green;
    }

    p{
        font: 700 30px $basic-font;
        color: $medium-green;
        margin: 10px auto 30px;
    }
}
