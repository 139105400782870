@import '../../styleVariables/styleVariables.scss';

.footer_wrapper{
    display: flex;
    background: $dark-green;
}

.footer_content{
    padding: 40px 0 30px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    div{
        width: 35%;
    }
}

.footer_logo{
    display: flex;
    gap: 5px;
    flex-flow: column;
}

.footer_logo{
    width: 100%;
    max-width: 200px;
}

.navigation_phone{
    font: 400 16px $basic-font;
    color: $light-green;
}

.footer_mav_list{
    li{
        width: 50%;
        float: left;
        padding: 5px 0; 
        list-style: none;

        a {
            text-decoration: none;
            border-bottom: 2px solid transparent;
            transition: .5s all ease;
            text-align: center;
            font: 400 15px $basic-font;
    
            &:hover {
                border-bottom: 2px solid $light-green;
            }
        }
    }
}

.footer_social{
    display: flex;
    flex-flow: column;
    text-align: center;
    gap: 10px;
}

.footer_social_img{
    width: 25px;
    transition: .5s all ease;
}

.footer_social_txt{
    font: 700 15px $basic-font;
    color: $light-green;
}

.footer_social_link{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background: $medium-green;
    border-radius: 5px;
    font:400 15px $basic-font;
    color: $light-green;
    text-decoration: none;
    text-transform: uppercase;
    transition: .5s all ease;

    &:hover {
        background: $light-green;
        color: $dark-green;

        .footer_social_img{
            background: $dark-green;
            padding: 5px;
            border-radius: 5px;
        }
    }
}

@media screen and (max-device-width: 768px) {
    .footer_content{
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        div {
            width: 100%;
        }
    }

    .footer_mav_list{
        text-align: center;
    }
    
    .footer_social {
        padding: 20px;

        p {
            width: 100%;
        }
    }
}