@import '../../styleVariables/styleVariables.scss';

.devision_information_wrapper,
.devision_doctors_wrapper{
    padding: 20px;
}

.devision_block_tit{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.devision_doctors_wrapper{
    background: $light-green;
}