@import '../../../styleVariables/styleVariables.scss';

.login_wrapper{
    background: linear-gradient(to top, rgba(0, 59, 70,.75), rgba(102, 165, 173,.66)),url('../../../img/franko_st.jpeg') no-repeat center;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_form{
    width: 100%;
    max-width: 450px;
    padding: 40px;
    gap: 30px;
    text-align: center;
    position: relative;
    border-radius: 10px;
    backdrop-filter: blur(10px);
}

.login_form_title{
    font: 700 20px $basic-font;
    color: $dark-green;
    padding: 20px 10px;
    border-radius: 5px;
    position: absolute;
    top: -20px;
    margin: 0 auto;
    left: 20%;
    background: $light-green;
    text-transform: uppercase;
}

// input styles

.input-container {
    position: relative;
    margin: 50px auto;
    width: 100%;
    font: 400 20px $basic-font;
    color: $light-green;
}

.input-container input[type="text"],
.input-container input[type="password"] {
    font-size: 20px;
    width: 100%;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 5px 0;
    background-color: transparent;
    outline: none;
    color: $light-green;
}

.input-container .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
    transition: all 0.3s ease;
    pointer-events: none;
}

.input-container input[type="text"]:focus ~ .label,
.input-container input[type="text"]:valid ~ .label,
.input-container input[type="password"]:focus ~ .label,
.input-container input[type="password"]:valid ~ .label{
    top: -20px;
    font-size: 16px;
    color: $dark-green;
}

.input-container .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: $dark-green;
    transform: scaleX(0);
    transition: all 0.3s ease;
}

.input-container input[type="text"]:focus ~ .underline,
.input-container input[type="text"]:valid ~ .underline,
.input-container input[type="password"]:focus ~ .underline,
.input-container input[type="password"]:valid ~ .underline {
    transform: scaleX(1);
}

.form_btn{
    padding: 10px 70px;
    cursor: pointer;
    background: $light-green;
    border: none;
    border-radius: 5px;
    font: 700 17px $basic-font;
    color: $dark-green;
    transition: .5s all ease;
    margin-bottom: 20px;

    &:hover {
        background: $medium-green;
        color: $light-green;
    }
}

.error_txt_form{
    font: 400 15px $basic-font;
    padding: 5px;
    color: $light-green;
    border-top:2px solid $light-green;
    border-bottom:2px solid $light-green;
}

// Eye settings
.container_eye {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -10px;
    top: 15px;
    cursor: pointer;
    font-size: 20px;
    user-select: none;
    fill: $light-green;
}

.container_eye .eye {
    position: absolute;
    animation: keyframes-fill .5s;
}

.container_eye .eye-slash {
    position: absolute;
    animation: keyframes-fill .5s;
    display: none;
}

.container_eye input:checked ~ .eye {
    display: none;
}

.container_eye input:checked ~ .eye-slash {
    display: block;
}

.container_eye input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

@keyframes keyframes-fill {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
    }
}