@import '../../styleVariables/styleVariables.scss';

.devision_info_wrapper{
    display: flex;
    flex-flow: column;
    align-items: center;

    & > div {
        width: 100%;
    }
}

.devision_info_content{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px 30px 30px;

    & > div {
        width: 40%;
    }
}

.devision_info{
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.devision_img{
    width: 100%;
    border-radius: 5px;
    max-width: 400px;
    box-shadow: 0px -1px 10px 1px rgba(7, 87, 91, 0.2);
}

.devision_schedule{
    display: flex;
    flex-flow: column;
    gap: 5px;
    align-items: flex-start;

    p{
        font: 700 17px $basic-font;
        color: $dark-green;

        span {
            font-weight: 400;
        }
    }
}

.devision_contact{
    font: 700 17px $basic-font;
    color: $dark-green;
    text-decoration: none;
}

.devision_specialties{
    display: flex;
    align-items: center;
    gap: 5px;
    background: $medium-light-green;
    border-radius: 5px;
    padding: 10px;

    hr {
        background: $light-green;
        height: 2px;
        border: none;
        margin-bottom: 5px;
    }
}

.devision_specialties_tit{
    font: 700 17px $basic-font;
    color: $white;
    text-align: center;
}

.devision_specialties_list {
    font: 400 15px $basic-font;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    li{
        padding: 5px 10px;
        background: $white;
        color: $dark-green;
        border-radius: 5px;
        list-style: none;
    }
}

@media screen and (max-device-width: 768px) {
    .devision_info_content{
        flex-flow: column;
        text-align: center;
        gap: 20px;

        & > div {
            width: 100%;
        }
    }
}
