@import '../../styleVariables/styleVariables.scss';

.patrons_wrapper{
    background: $light-green;
}

.patron_box{
    text-align: center;
    padding: 20px;
}

.patron_name{
    font: 700 20px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 30px auto;
    text-transform: uppercase;
}