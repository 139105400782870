@import '../../styleVariables/styleVariables.scss';

.redactor_wrapper {
    width: 100%;
    height: 100%;
    min-height: 300px;
    background: $light-green;
}

.public-DraftStyleDefault-block{
    margin: 0 auto !important;
}