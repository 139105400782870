@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:ital,wght@0,400;0,700;0,800;1,400&family=PT+Serif:wght@400;700&display=swap');

*{
  padding:0px;
  margin:0px;
  box-sizing:border-box;
}

html,
body{
    overflow-x: hidden;
    user-select: none;
}