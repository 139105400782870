@import '../../styleVariables/styleVariables.scss';

.doctorsSlider{
    padding: 0 0 30px !important;
}

.doctor_block_slide{
    padding: 20px;
    margin: 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px -1px 10px 1px rgba(7, 87, 91, 0.2);
    transition: .5s all ease;

    &:hover {
        background: $dark-green-opacity;

        .doctor_block_info_name,
        .doctor_block_info_specialty{
            color: $white;
        }
    }
}

.doctor_block_slide_box{
    text-decoration: none;
    text-align: center;
}

.doctor_block_info_wrapper{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    hr{
        width: 100%;
        border: none;
        height: 2px;
        background: $dark-gradient;
    }
}

.doctor_block_info_name{
    font: 700 17px $basic-font;
    color: $dark-green;
    transition: .5s all ease;
}

.doctor_block_info_specialty{
    font: italic 15px $basic-font;
    color: $dark-green;
    transition: .5s all ease;
}

.spec_doc_tit_text{
    font:700 25px $basic-font;  
    color: $dark-green-opacity;
    text-align: center;
    margin-bottom: 30px;
}