@import '../../styleVariables/styleVariables.scss';

.devision_box_tit{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.devision_box_content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.devision_box{
    padding: 20px 0;
    &:nth-child(even) {
        background-color: $light-green;
    }
}

.devision_box_schedule,
.devision_box_schedule_contacts{
    margin: 10px 0 10px 0;
    text-decoration: underline;
}

.devision_box_info{
    display: flex;
    flex-flow: column;
    gap: 2px;

    p {
        font: 700 16px $basic-font;
        color: $dark-green;

        span {
            font-weight: 400;
        }
    }

}
.devision_contact_mobile,
.devision_contact_stac{
    font: 700 16px $basic-font;
    color: $dark-green;
    text-decoration: none;

    span {
        font-weight: 400;
    }
}

.devision_contact_stac{
    margin-bottom: 20px;
}

@media screen and (max-device-width: 768px) {
    .devision_box_content{
        flex-flow: column;
        justify-content: center;
        gap: 20px;

        div {
            width: 100%;
            text-align: center;
        }
    }

}