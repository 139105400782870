@import '../../styleVariables/styleVariables.scss';

// Services

.services_tab_wrapper{
    padding: 20px;
}

.services_tab_content{
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.tab_list{
    justify-content: center !important;
}

.tab_head{
    th{
        font:900 13px $basic-font;
        color: $dark-green;

        &:last-child{
            text-align: right;
        }
    }
}

.tab_row {
    width: 100%;
    transition: .5s all ease;
    border-radius: 5px;

    &:hover {
        background: $light-green;
    }

    th {
        width: 50%;
        text-transform: none;
        padding: 10px;
        font-size: 13px;

        &:last-child{
            text-align: right;
        }
    }
}

.css-1mbanyi{
    font-family: $basic-font;
    font-weight: 700;
}

.css-1mbanyi[aria-selected=true], .css-1mbanyi[data-selected]{
    background: $dark-green;
    color: $white;
    font-family: $basic-font;
    font-weight: 700;
}

// End services
