@import '../../styleVariables/styleVariables.scss';

// Header wrapper
.header_wrapper{
    padding: 20px;
    background: $dark-green;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}


// Header content
.header_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

// Header logo
.header_logo_img{
    width: 100%;
    max-width: 210px;
}

// Header navigation
.header_nav_list{
    display: flex;
    flex-flow: wrap;
    gap: 20px;

    a{
        font: 400 18px $basic-font;
        color: $light-green;
        text-decoration: none;
        border-bottom: 2px solid $dark-green;
        transition: .5s all ease;
        opacity: .8;

        &:hover {
            border-bottom: 2px solid $light-green;
            opacity: 1;
        }
    }
}

//Header social and contacts
.header_social{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.header_social_contact{
    display: flex;
    flex-flow: column;
    gap: 3px;
    padding: 0px 10px;
    border-right: 2px solid $light-green;
}

.header_social_contact_txt{
    font: 400 15px $basic-font;
    color: $light-green;
    text-decoration: none;
    opacity: .8;
    transition: .5s all ease;

    &:hover {
        opacity: 1;
    }
}

.header_social_link{
    transform: scale(1);
    transition: .3s all ease;

    &:hover{
        transform: scale(.9);
    }
}

.header_social_img{
    width: 100%;
    max-width: 30px;
}

@media screen and (max-device-width: 768px) {
    .header_nav_list,
    .header_social_contact_txt,
    .header_social_link{
        display: none;
    }

    .wrapper_burger{
        display: block;
    }
}