@import '../../styleVariables/styleVariables.scss';

.services_tab_box{
    padding: 20px;
}

.services_block_tit{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.services_instruction_text{
    font: 400 20px $basic-font;
    color: $dark-green;
}