@import "../../styleVariables/styleVariables.scss";

.doctor_content {
    min-height: 100vh;
}

.doctor_info {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    padding: 30px 20px;
}

.doctor_block_tit {
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.doctor_info_img_box {
    width: 35%;
}
.doctor_info_text {
    width: 50%;
    text-align: left;
}

.doctor_info_image {
    width: 100%;
    max-width: 250px;
}

.doctor_info_name {
    font: 700 30px $basic-font;
    color: $dark-green;
}

.doctor_info_specialty {
    font: italic 400 17px $basic-font;
    color: $medium-green;
    margin-bottom: 10px;
}

.doctor_info_special_info {
    font: 400 17px $basic-font;
    color: $dark-green;
    margin-bottom: 10px;
}

.doctor_info_explanation {
    font: 400 12px $basic-font;
    color: $white;
    background: $medium-light-green;
    padding: 5px 7px;
    border-radius: 5px;
    margin: 10px 0 10px 0;
}

.doctor_deep_info_box {
    display: flex;
    gap: 10px;
    flex-flow: column;
}

.doctor_deep_info_tit {
    font: 700 17px $basic-font;
    color: $dark-green;
    border-bottom: 2px solid $medium-green;
}

.doctor_deep_info_education,
.doctor_deep_info_category,
.mnp_link {
    font: 700 15px $basic-font;
    color: $dark-green;

    span {
        font-weight: 400;
    }
}
.mnp_link {
    transition: 0.5s all ease;
}

.doctors_slider_wrapper {
    background: $light-green;
    padding: 20px;
}

@media screen and (max-device-width: 768px) {
    .doctor_info {
        flex-flow: column;
        justify-content: center;
        gap: 20px;

        div {
            width: 100%;
            text-align: center;
        }
    }
}
