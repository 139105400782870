@import '../../styleVariables/styleVariables.scss';

.link_button{
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background: $dark-green-opacity;
    font: 700 17px $basic-font;
    color: $light-green;
    text-decoration: none;
    text-align: center;
    transition: .5s all ease;
    display: table;

    &:hover {
        background: $dark-green;
        transform: scale(1.05);
    }
}