@import '../../styleVariables/styleVariables.scss';

.doctors_wrapper{
    text-align: center;
}

.doctors_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
}

.doctors_filters{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto 20px;
    gap: 10px;
}

.doctors_search{
    width: 100%;
    max-width: 50%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: $medium-light-green;
    transition: .5s all ease;
    outline: none;

    &::placeholder{
        color: $white;
        font: 400 15px $basic-font;
        opacity: .7;
    }

    &:focus {
        background: $light-green;

        &::placeholder{
            color: $dark-green;
        }
    }
}

.doctors_limit{
    background: $medium-light-green;
    color: $white;
    font: 700 15px $basic-font;
    border-radius: 5px;
    padding: 7px;
    border: none;
    outline: none;
}

.doctors_limit_label{
    color: $dark-green;
    font: 700 15px $basic-font;
}

.doctor_box{
    width: 100%;
    max-width: 350px;
    text-align: center;
    min-height: 135px;
    display: flex;
    gap: 10px;
    flex-flow: column;
    box-shadow: 0px -1px 10px 2px rgba(7, 87, 91, 0.2);
    padding: 10px;
    border-radius: 5px;
    transition: 0.5s all ease;

    &:hover { 
        transform: scale(1.05);
    }
}

.doctor_name{
    font: 700 20px $basic-font;
    color: $dark-green;
}

.doctor_hr{
    border: none;
    height: 3px;
    background: $dark-gradient;
}

.doctor_specialty{
    font: italic 400 17px $basic-font;
    color: $dark-green;
}
.doctor_btn{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}

.doctors_pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 20px auto;

    p{
        font: 700 17px $basic-font;
        color: $dark-green;
        cursor: pointer;
        transition: .5s all ease;
        padding: 5px 10px;

        &:hover { 
            background: $dark-green;
            color: $white;
            border-radius: 5px;
        }
    }
}

.actived_pagination{
    background: $dark-green;
    color: $white !important; 
    border-radius: 5px;
}

@media screen and (max-device-width: 768px) {
    .doctors_content{
        flex-flow: column;
    }
}