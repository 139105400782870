@import '../../styleVariables/styleVariables.scss';

.new_info_box{
    padding: 20px 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.new_info_tit_block{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.new_info_box_content {
    display: flex;
    flex-flow: column;
    gap: 20px;
    margin-bottom: 30px;
    justify-content: center;
}

.new_info_box_img{
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
}

.new_info_img{
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px -1px 10px 1px rgba(7, 87, 91, 0.2);
    object-fit: cover;
    aspect-ratio: 3/2;
    max-width: 600px;
    margin: 0 auto;
}

.new_info_box_desc{
    display: flex;
    flex-flow: column;
    gap: 20px;
    justify-content: flex-start;
}

.new_info_date{
    font: italic 400 15px $basic-font;
    color: $dark-green;
}

.new_info_desc{
    font: 400 17px $basic-font;
    color: $medium-green;
}

.new_info_slider{
    background: $light-green;
    padding: 20px;
}

@media screen and (max-device-width: 768px) {
    .new_info_box_content {
        flex-flow: column;
    }
    .new_info_box_desc{
        width: 100%;
    }
    .new_info_box_img{
        max-width: 100%;
    }
}