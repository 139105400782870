// Colors
$dark-green: #003b46;
$dark-green-opacity: rgba(0, 60, 70, 0.85);
$medium-green: #07575b;
$medium-light-green: #66a5ad;
$light-green: #ebf4f6;
$dark-gradient: linear-gradient(270deg, #003b46 0.23%, #66a5ad 99.77%);
$white: #fff;
$burger-overlay: #07575b77;

//Fonts
$sans-font: Open Sans;
$basic-font: Montserrat;
