.spinner {
    --clr: rgb(0, 113, 128);
    --gap: 6px;
      /* gap between each circle */
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap);
    margin: 0 auto;
}

.spinner_full{
    min-height: 100vh;
}

.spinner span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--clr);
    opacity: 0;
}

.spinner span:nth-child(1) {
    animation: fade 1s ease-in-out infinite;
}

.spinner span:nth-child(2) {
    animation: fade 1s ease-in-out 0.33s infinite;
}

.spinner span:nth-child(3) {
    animation: fade 1s ease-in-out 0.66s infinite;
}

@keyframes fade {
    0%, 100% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }
}
