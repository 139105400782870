@import '../../styleVariables/styleVariables.scss';

.intership_accordion_content{
    margin: 30px auto;
}

.intership_accord_btn {
    border-radius: 5px;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    font: 400 17px $basic-font;
    color: $white;
    background: $dark-green-opacity;
    transition: 0.5s all ease;
    border: none;

    &:hover {
        background: $dark-green !important;
    }
}

.intership_accord_text{
    font: 700 15px $basic-font;
    color: $dark-green; 
    margin: 10px auto 15px;
}

.table_internship_head {
    background: $white;

    th {
        padding: 5px;
        font: 700 13px $basic-font;
        color: $dark-green !important;
        border-bottom: 2px solid $dark-green-opacity;

        &:first-child{
            width: 10px;
            text-align: center;
        }
    }
}

.table_internship_row{
    transition: .5s all ease;

    &:hover {
        background: $white;
    }

    th {
        padding: 5px;
        font: 400 14px $basic-font;
        color: $dark-green !important;
        border-bottom: 1px solid $light-green;
        border-radius: 5px;

        &:first-child{
            width: 10px;
            text-align: center;
        }
    }
}

.empty_intership{
    font: 700 20px $basic-font;
    color: $medium-green;
    text-align: center;
    padding: 20px 10px;
    border-top: 2px solid $dark-green;
    border-bottom: 2px solid $dark-green;
}