@import '../../styleVariables/styleVariables.scss';

.wrapper_burger{
    display: none;
}
.css-1oxhx2p{
    z-index: 3;
}
.burger_overlay{
    min-height: 100vh;
    background: $burger-overlay
}
.burger_content{
    max-width: 50%;
    background: $dark-green !important;
}
.burger_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: $dark-green
}

.burger_logo_link{
    margin: 0 auto;
    outline: none;
}

.burger_logo{
    width: 200px;
    background: $white;
    border-radius: 5px;
    margin: 10px auto;
}
.open_burger{
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
}
.close_burger{
    margin-right: 10px;
    cursor: pointer;
}
.burger_hr{
    height: 2px;
    background : $white;
}
.burger_main_links{
    display: flex;
    flex-flow: column;
    gap: 5px;
    padding: 5px 0;
}
.burger_main_link{
    display: block;
    padding: 5px;
    text-align: center;
    font: 700 15px $basic-font;
    text-decoration: none;
    color: $white;
    outline: none;
    transition: .5s all;

    &:hover{
        background: $white;
        color: $dark-green;
    }
}

.burger_contacts{
    display: flex;
    flex-flow: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 20px auto;

    a{
        font: 400 12px $basic-font;
        color: $white;
        text-decoration: none;
    }
}

@media screen and (max-device-width: 768px) {
    .wrapper_burger{
        display: block;
    }
}