@import '../../styleVariables/styleVariables.scss';

.help_information_wrapper,
.patrons_wrapper,
.help_from_us_wrapper{
    padding: 20px;
}

.help_title_text{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}

.help_from_us_wrapper{
    display: flex;
    flex-flow: column;
    gap: 5px;
}

.help_from_us_text{
    font: 400 17px $basic-font;
    color: $dark-green;
}

.help_from_us_text_for_ua{
    font: italic 700 20px $basic-font;
    margin: 10px auto 30px;
}