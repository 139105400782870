@import '../../styleVariables/styleVariables.scss';

.treatment_schedule_block{
    padding: 20px 20px 50px;
}

.tretment_title_text{
    font: 700 30px $basic-font;
    background: $dark-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 20px 0 40px;
    text-transform: uppercase;
}