@import '../../styleVariables/styleVariables.scss';

.newsSlider{
    padding: 0 0 30px !important;
}

.news_block_slide{
    margin: 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px -1px 10px 1px rgba(7, 87, 91, 0.2);
    transition: .5s all ease;

    &:hover {
        background: $light-green;
    }
}

.news_block_slide_box{
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 160px;
    max-height: 300px;
    text-decoration: none;
}

.news_block_img_wrapper{
    width: 100%;
    max-width: 30%;
}

.news_block_img_slide{
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
}

.news_block_info_wrapper{
    padding: 20px;
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 70%;
}

.news_block_date_slide{
    font: 400 15px $sans-font;
    font-style: italic;
    color: $medium-green;
    margin-bottom: 15px;
}
.news_block_title_slider{
    font: 700 20px $basic-font;
    color: $dark-green;
}
.news_block_short_descr_slide{
    font: 400 15px $basic-font;
    color: $medium-green;
}

.news_block_title_slider,
.news_block_short_descr_slide{
    word-wrap: break-word;
}


.swiper-pagination{
    margin-top: 30px !important;

    span {
        width: 15px;
        height: 15px;
        background: $dark-green !important;
    }
}

// Pagination dots
.swiper-pagination-bullet-active{
    background: $medium-green !important;
}